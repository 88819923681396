import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import logo from '../../img/logo.png';
import './NavBar.css';
import { Link } from 'react-router-dom';
import Secciones from './Secciones.jsx';

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="fixed" id='NavBar'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/Home"><img src={logo} id='imgLogo' alt='logo organizacion-es.com nav' width="357.17" height="78"></img></Link>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
          </Typography>          
          <Typography><button id='NavButton'><Link to='/Home' id='NavLink' >HOME</Link></button></Typography>
          <Secciones />
          <Typography><button id='NavButton'><Link to='/Herramientas%20de%20Diagnostico' id='HerramientasLink' >HERRAMIENTAS DE DIAGNOSTICO</Link></button></Typography>
          <Typography><button id='NavButton'><Link to='/Sobre%20Nosotros' id='NavLink' >SOBRE NOSOTROS</Link></button></Typography>
          <Typography><button id='NavButton'><Link to='/Contacto' id='NavLink' >CONTACTO</Link></button></Typography>




          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 900,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
            id='pagesNavbar'>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;