import { Route, HashRouter, Routes } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Contacto from './components/Contacto/Contacto';
import { initializeApp } from "firebase/app";
import ItemDetailContainer from './components/ItemDetail/ItemDetailContainer';
import Nosotros from './components/Nosotros/Nosotros';
import Herramientas from './components/Herramientas/Herramientas';



function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyDKh68TOBKBbcZO0KAFMM9QGnlCIiDkwfI",
    authDomain: "organizaciones-384ef.firebaseapp.com",
    projectId: "organizaciones-384ef",
    storageBucket: "organizaciones-384ef.appspot.com",
    messagingSenderId: "894415718379",
    appId: "1:894415718379:web:0957a5d712698f1372bbae"
  };
  
  initializeApp(firebaseConfig);

  return (
    <>
    <HashRouter>
        <NavBar />
          <Routes>
            <Route path='/' element= {<Home />}/>
            <Route path='/Home' element= {<Home />}/>  
            <Route path='/Contacto' element= {<Contacto />}/>
            <Route path='/seccion/:idProd' element= {<ItemDetailContainer />}/>
            <Route path='/Sobre%20Nosotros' element= {<Nosotros />}/>
            <Route path='/Herramientas%20de%20Diagnostico' element= {<Herramientas />}/>
          </Routes>
        <Footer />    
    </HashRouter>
    </> 
  );
}

export default App;
