import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import './Contacto.css';
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import {Helmet} from 'react-helmet-async';
import Swal from 'sweetalert2';


export default function Contacto() {
    const form = useRef();
    const Swal = require('sweetalert2');

    function sendEmail(e) {

        e.preventDefault();

    emailjs.sendForm('service_hft0f0l', 'template_a67rqmk', form.current, '0fLTkjC4bQEUJpPd-')
      .then((result) => {
          console.log(result.text);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Su mensaje se envio correctamente',
            showConfirmButton: false,
            timer: 1500
          })
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };


  return (
  <>  
    <Helmet>
      <title>Organizacion-es | Contacto</title>
    </Helmet>
    <div id='formContacto'>
    <Grid>
    <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" }} id='Contacto'>
      <CardContent>
        <Typography gutterBottom variant="h5">
          Contactanos
      </Typography> 
        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
      </Typography> 
        <form ref={form} onSubmit={sendEmail}>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <TextField  id='textField' placeholder="Escriba su nombre y apellido" label="Nombre y Apellido" variant="filled" type="text" name="user_name" required/> 
            </Grid>
            <Grid item xs={12}>
              <TextField  id='textField' type="email" name="user_email" placeholder="Ingrese su email" label="Email" variant="filled" required/>
            </Grid>
            <Grid item xs={12}>
              <TextField  id='textField' label="Mensaje" name="mensaje" multiline rows={4} placeholder="Escriba su mensaje aqui" variant="filled" required/>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" value="send" variant="contained" color="primary" fullWidth>Enviar</Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
    </Grid>
    </div>
  </>  
  )
}

