import imagen from '../../img/banner2.jpg';
import './Nosotros.css';
import logo from '../../img/logo.png';
import logo_hpi from '../../img/logo_hpi.png';
import logo_partner from '../../img/hpi_partner.png';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import GroupsIcon from '@mui/icons-material/Groups';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { Helmet } from 'react-helmet-async';

export default function Nosotros() {
  return (
    <>
    <Helmet>
      <title>Organizacion-es | Nosotros</title>
      <meta name="description" content="Coaching Organizacional potenciando el desarrollo de los Ejecutivos, Equipos, Emprendedores,
        Coaching personal y acompañamiento en proyectos. Herramientas de diagnóstico DISC,
        Inteligencia Emocional y Competencias, entre otras. Procesos diseñados específicamente en
        función de las necesidades de las Organizaciones."></meta>
    </Helmet>
    <div>
      <div id='DivBanner'>
        <img src={imagen} id='banner' alt='banner manos organizacional coaching' width="1920"  height="auto"></img>
      </div>
      <div id='container'>
          <div id='cardNosotros'><GroupsIcon id='cardIcon'/><h3>Misión</h3><p>Potenciar el desarrollo de las personas y los equipos de trabajo.</p></div>
          <div id='cardNosotros'><RemoveRedEyeIcon id='cardIcon'/><h3>Visión</h3> <p>Ser la entidad de referencia en el desarrollo del potencial de las personas y los equipos
          de trabajo convirtiéndonos en socios estratégicos de las organizaciones.</p></div>
          <div id='cardNosotros'><BeenhereIcon id='cardIcon'/><h3>Valores</h3><p>Valoramos la confianza, el compromiso y el respeto.</p></div>
      </div>
        <div id='divNosotros'>
          <div id='divText'>
              <h2>Nuestra Historia</h2>
                  <p>
                  <strong>OES</strong> fue creada por Esteban Barac con el objetivo de generar un impacto positivo en las
                  Organizaciones, Emprendedores, Ejecutivos y Equipos.
                  </p>
                  <p>
                  A través del Coaching y utilizando distintas herramientas de diagnóstico que se adecuan a las
                  distintas necesidades, siendo Partner de HPI Internacional , proveemos programas de
                  desarrollo de habilidades, autoconocimiento, mejora del desempeño, comunicación efectiva,
                  negociación, venta, liderazgo y otros ámbitos.
                  </p>
                  <div id='divImgCoach'>
                    <img  id='imgCoach' src='https://cdn.discordapp.com/attachments/841443299839574017/1039913282858917918/nosotros-coach.jpg' />
                  </div>
                  <div id='divNombre'>
                    <h4>Esteban Barac</h4>
                    <h4>Director de OES</h4>
                    <h4>Coach Organizacional de Ejecutivos y Equipos  </h4>
                  </div>
                  <div id='divLogos'>
                    <img src={logo_partner} id='logoPartner' alt='logo premium partner hpi' width="352" height="105.66"/>
                    <img src={logo_hpi} id='logoHPINosotros' alt='logo hpi human performance institute' width="320" height="165.39"/>
                  </div>  
                    <img src={logo}  id='logoOES' alt='logo organizacion-es coaching organizacional' width="432" height="94.33"/>  
          </div>
        </div>
    </div>
  </>
  )
}
