import { useEffect, useState } from 'react'
import CardContainer from './CardContainer/CardContainer.jsx';
import { useParams } from 'react-router-dom';
import Banner from './banner/Banner';
import './Home.css';
import { Helmet } from 'react-helmet-async';

export default function Home() {

  
      let productosMock = [
        {id: 'yUhhbFHhDFJ9BaDjEe9D', category: 'coaching', title: 'Organizacional', text1:'⦁	Gestión del Cambio ', text2:'⦁	Optimización del Proceso de Feedback', text3:'⦁	Acompañamiento del Proceso de mejora de Performance ', text4:'⦁	Modelo de Competencias Laborales', text5:'⦁	Desarrollo de Habilidades durante el Proceso de promoción', img:'https://cdn.discordapp.com/attachments/841443299839574017/1024720893852794902/iStock-organizacional.jpg', img2:'https://cdn.discordapp.com/attachments/841443299839574017/1024715874902487050/iStock-organizacional.jpg'},
        {id: 'f4PwRbCOYrjBZ53u1GDa', category: 'coaching', title: 'Ejecutivo', text1:'⦁	Desarrollo de Habilidades', text2:'⦁	Obtención de Objetivos', text3:'⦁	Herramientas para el Liderazgo', text4:'⦁	Herramientas para la Conversación Efectiva', text5:'⦁	Inteligencia Emocional', img: 'https://cdn.discordapp.com/attachments/841443299839574017/1003070735025188934/pexels-anders-kristensen-447570.jpg', img2: 'https://cdn.discordapp.com/attachments/841443299839574017/1003105793173880902/pexels-anders-kristensen-447570_1.jpg'},
        {id: 'qEFYa9lgJvTtITKbUnOY', category: 'coaching', title: 'Equipos', text1: '⦁	Desarrollo de estilos de Conducción y Liderazgo.', text2: '⦁	Desarrollo de estrategias de Comunicación y Clima laboral.', text3: '⦁	Reorganización funcional.', text4: '⦁	Alineación Estratégica.', text5: '⦁	Equipos de Alto Desempeño', img: 'https://cdn.discordapp.com/attachments/841443299839574017/1024720511370006538/iStock-equipos.jpg', img2: 'https://cdn.discordapp.com/attachments/841443299839574017/1024719589856260096/iStock-equipos.jpg'},
        {id: 'Ys1DllXaQCNJtygiE7xF', category: 'coaching', title: 'Gestión de Personas', text1:'⦁	Desarrollo de Habilidades de Liderazgo', text2:'⦁	Herramientas de Diagnostico Inteligencia Emocional ', text3:'⦁	Herramientas de Diagnostico por modelo de Competencias  ', text4:'⦁	Herramientas de Diagnostico de DISC ', text5:'⦁	Acompañamiento en Procesos de Desarrollo de Habilidades según rol', img: 'https://cdn.discordapp.com/attachments/841443299839574017/1003071911183519744/pexels-kampus-production-8190804.jpg', img2: 'https://cdn.discordapp.com/attachments/841443299839574017/1003106663408082944/pexels-kampus-production-8190804_1.jpg'},
        {id: 'zEz12cvGpmlu840hyDQp', category: 'coaching', title: 'Proyectos y Emprendimientos', text1:'⦁	Acompañamiento a Emprendedores', text2:'⦁	Planificación de Proyectos', text3:'⦁	Acompañamiento en etapas de Viabilidad, Control y Gerenciamiento', text4:'⦁	Conformación de Equipos', text5:'⦁	Socios Estratégicos', img: 'https://cdn.discordapp.com/attachments/841443299839574017/1003071495649644544/pexels-thisisengineering-3861952.jpg', img2:'https://cdn.discordapp.com/attachments/841443299839574017/1003106141158522910/pexels-thisisengineering-3861952_1.jpg'},
        {id: 'rWWy9ahzN5ro2wFXMZxH', category: 'coaching', title: 'Personal', text1:'⦁	Planes de Acción para Nuevos desafíos', text2:'⦁	Conversaciones difíciles', text3:'⦁	Habilidades de Negociación', text4:'⦁	Inteligencia Emocional', text5:'⦁	Logro de Objetivos', img: 'https://cdn.discordapp.com/attachments/841443299839574017/1003071728987148288/pexels-s-migaj-747964.jpg', img2: 'https://cdn.discordapp.com/attachments/841443299839574017/1003106471992631326/pexels-s-migaj-747964_1.jpg'},
      ];

      const{idCat} = useParams();
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(false);
      const [resultado, setResultado] = useState([]);

      useEffect(()=>{
      const infoCards = new Promise((res, rej) => {
          setResultado ([]);
          setLoading(true);
          setError(false);
          
        setTimeout(() => {
          (!idCat) ? res(productosMock) : res(productosMock.filter(item => item.category==idCat));
        }, );
      });
    
      infoCards 
      .then((result) =>{
        setResultado(result);
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });   
    
    }, [idCat]);
    
    console.log(resultado);
      
    return (<>
    <Helmet>
      <title>Organizacion-es | Home</title>
      <meta name="description" content="Coaching Organizacional potenciando el desarrollo de los Ejecutivos, Equipos, Emprendedores,
        Coaching personal y acompañamiento en proyectos. Herramientas de diagnóstico DISC,
        Inteligencia Emocional y Competencias, entre otras. Procesos diseñados específicamente en
        función de las necesidades de las Organizaciones.">
        </meta>
        <meta name="keywords" content="coaching,organizacional,test,hpi,oes"/>
    </Helmet>
    <div>
      <Banner />
    </div>
      <div id='loading'>{loading && 'Loading...'}</div>
      <div>{error && 'Hubo un error inesperado'}</div>
        <div id='containerHome'>
          <CardContainer infoCards={resultado} />
        </div>       
      </>
    );
    }
  