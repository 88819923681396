import './Herramientas.css';
import { Helmet } from 'react-helmet-async';

export default function Herramientas() {
  return (
  <>
    <Helmet>
      <title>Organizacion-es | Herramientas</title>
    </Helmet>
    <div>
      <div id='divHerramientas'>
        <h2>Herramientas de Diagnóstico</h2><p>Contamos con HPI DISC en sus versiones Standard y Premiun, HPI- EI una
          poderosa herramienta para evaluar el nivel de habilidades de Inteligencia
          Emocional con el modelo de Daniel Goleman, en que encontrarás los
          reportes enfocados a Desarrollo, Liderazgo y Educación. También
          contamos con HPI – Competence una completa herramienta que te permite
          evaluar 30 competencias transversales y dar apoyo al desarrollo continuo.</p>
          <img src='https://images-ext-1.discordapp.net/external/Pa3AUlZCUQEnXZKClwtlVobG_KaBwyCxTe8t9TsVWtg/https/hpioficial.com/wp-content/uploads/2020/10/logo-hpi.png' id='logoHpiHerramientas' alt='logo human performance institute' width="320" height="157.34"/>
          </div>
      <div id='ContainerHerramientas'>
        <div id='cardHerramientas'>
          <h3>HPI DISC</h3><p>Creado por el investigador William Marston ha sido utilizado por más de 60 millones de
          personas en todo el mundo y cuenta con numerosos estudios de validación que dan cuenta de
          su poder predictivo del comportamiento y el Estilo que puede desplegar una persona.</p><p>Gracias a este instrumento podrás potenciar el autoconocimiento, la mejora del desempeño, la
          comunicación efectiva, negociación, venta, liderazgo y muchos otros ámbitos claves en la
          selección de personas, el desarrollo de colaboradores, equipos y organizaciones.</p><p>Conoce nuestros reportes de HPI DISC en sus versiones Standard y Premium.</p>
        </div>
        <div id='cardHerramientas'>
          <h3>HPI EQ</h3><p>La inteligencia emocional ha demostrado de forma sostenida en numerosas
          investigaciones que constituye un factor clave de éxito en la vida de las personas.
          Estas habilidades nos ayudan a ser más efectivos, lograr mejores relaciones
          interpersonales, aumentar nuestro bienestar personal y construir una mejor calidad
          de vida.</p><p>Por otra parte, se ha constatado que la inteligencia emocional es la competencia que
          mejor correlaciona con los resultados profesionales y la capacidad de liderar con
          eficacia. Por estas razones, y muchas otras, evaluamos el nivel de desarrollo en
          inteligencia emocional y entregamos las recomendaciones de desarrollo para que las
          personas lleguen más lejos en distintas áreas de su vida.</p><p>Conoce nuestros informes de Inteligencia Emocional con foco en el Desarrollo,
          Liderazgo y Educadores.</p>
          </div>
        <div id='cardHerramientas'>
          <h3>HPI Competence</h3><p>Las competencias transversales son aquellas que marcan la diferencia entre un resultado
          sobresaliente que no lo es, por esta razón son cada vez más importantes.</p><p>HPI Competence por ejemplo que es una herramienta que te permite diseñar perfiles de
          competencia de forma ágil y dinámica, así como evaluar las competencias de colaboradores y
          directivos, identificando su grado de desarrollo y las áreas de oportunidad para que sigan
          creciendo.</p><p>Nunca más harás gestión con “lo que te pareció”, ahora podrás hacerlo con conocimiento de
          las competencias existentes en cada persona, estableciendo criterios claros para la elección y
          potenciación de profesionales.</p>
          </div>
      </div>
    </div>
    
  </>
  )
}
