import Cards from '../Card/Cards.jsx';
import './CardContainer.css';

export default function CardContainer({infoCards}) {
  return (
          <div id='CardContainer'>
            {infoCards?.map(infoCards => <Cards key={infoCards.id} infoCards={infoCards}/>)}
          </div>
   
  )
}