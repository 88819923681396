import './Footer.css';
import logoOES from '../../img/logo.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div id='Footer'>
      <img src={logoOES} id='logoFooter' alt='logo organizacion-es' width="560" height="122.28"></img>
        <div id='contactoFooter'>
        <Link to='/Contacto' id='LinkFooter'><EmailIcon id='contactoIcons'/><p>info@organizacion-es.com</p></Link>
        </div>
        <div id='contactoFooter'>
        <a id='LinkFooter' href='https://api.whatsapp.com/send?phone=5492993272534'><WhatsAppIcon id='contactoIcons'/><p>+54 9 2993272534</p></a>
        </div>
        <div id='contactoFooter'>
        <a id='LinkFooter' href='https://www.instagram.com/oes.organizaciones/?hl=es'><InstagramIcon id='contactoIcons'/><p>@oes.organizaciones</p></a>
        </div>
        
        <div id='contactoFooter'>
        <a id='LinkFooter' href='https://www.linkedin.com/company/oes-organizaciones/'><LinkedInIcon id='contactoIcons'/><p>OES ORGANIZACIONES</p></a>
        </div>
      <h1>Organizacion-es, Copyright @ 2022</h1>
      
    </div>
  )
}
