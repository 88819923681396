import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import './Cards.css';
import { motion } from 'framer-motion';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    -
  </Box>
);


export default function BasicCard({ infoCards}) {
  const { id, title, text1, text2, text3, text4, text5, img, img2 } = infoCards;

  const [isOpen, setIsOpen] = useState(false);


  return (
    <>
    <Card sx={{ minWidth: 275 }} id='cards'>
      <CardContent>
        { isOpen ? <motion.img src={img} id='imgCard' alt='secciones organizacion-es' width="528" height="132" className='OpenCardImg' /> : <motion.img src={img2} id='imgCard' alt='secciones organizacion-es' width="512" height="384" className='ClosedCardImg' />  }
        <Typography variant="h4" component={motion.div} id='tituloCard'>
          {title}
        </Typography>
          {isOpen ?  <Button id='butonCerrar' onClick={() => setIsOpen(!isOpen)}><ArrowDownwardIcon  id='iconCerrar'/></Button> : <Button id='iconBoton' onClick={() => setIsOpen(!isOpen)}>Ver Mas</Button>}
        {isOpen && ( <>
        <div id='textoCardAbierto'>
          <Typography variant="h6" id='textosCard' component={motion.h6}>
            {text1}
            <br />
            </Typography><Typography variant="h6" id='textosCard' component={motion.h6}>
              {text2}
            <br />
            </Typography> 
          <Typography variant="h6" id='textosCard' component={motion.h6}> 
            {text3}
            <br />
          </Typography>
          <Typography variant="h6" id='textosCard' component={motion.h6}>
            {text4}
            <br />
          </Typography>
          <Typography variant="h6" id='textosCard' component={motion.h6}>
            {text5}
            <br />
          </Typography>
        </div>
        <Link to={`/seccion/${id}`} id="Links"><Button id='iconBoton'>Leer Mas</Button></Link>
        </>)}
      </CardContent>
    </Card>
    </>
  );
}