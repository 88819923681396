import React from 'react'
import imagen from '../../../img/banner1.png'
import './Banner.css';

export default function Banner() {

    return (
      <div id='DivBanner'>
        <img src={imagen} id='banner' alt='banner organizacion-es con titulo'width="1890" height="500"></img>
      </div>
    )
}
