import './ItemDetail.css';
import Contacto from '../Contacto/Contacto';
import "animate.css/animate.min.css";
import {Helmet} from 'react-helmet-async';

export default function ItemDetail( { secciones } ) {
    const { id, title, img, img2, text1, text2, text3, text4, text5, text6, text7, text8, textN1, textN2, textN3, textN4, textN5 } = secciones;
    const title_downcase = title.toLowerCase();
    return (
   <> 
    <div id='itemDetailContainer'>
        <div>
        <h2 id='titleDiv'>{title}</h2>
        <div id="divDetail">
            <div id='imgDetail'>
                <img src={img2} id='img'width="512" height="384" alt="secciones coaching organizacional, organizacion-es"/>
            </div> 
            <div id='textDetail'>
                    <p id='text1Card'>{text1}</p>    
                <div id='divItems1'>
                    <p id='text2Card'>{text2}</p>
                    <p id='text3Card'>{text3}</p>
                    <p id='text4Card'>{text4}</p>
                    <p id='text5Card'>{text5}</p>
                </div>
                <div id='divItems2'>
                    <p id='textN1Card'>{textN1}</p>
                    <p id='textN2Card'>{textN2}</p>
                    <p id='textN3Card'>{textN3}</p>
                    <p id='textN4Card'>{textN4}</p>
                    <p id='textN5Card'>{textN5}</p>
                    <p id='text6Card'>{text6}</p>
                    <p id='text7Card'>{text7}</p>
                    <p id='text8Card'>{text8}</p>
                </div>
            </div>
        </div>
            <Contacto id='contacto'/>
            </div>     
    </div>    
  </>      
    )
}
